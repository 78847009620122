<template>
  <v-card class="vidyo__container" flat>
    <iframe
      id="videoCall"
      :src="VIDYO_IFRAME_URL"
      style="width: 100%; border-width: 0px; height: 100%"
      allow="geolocation; microphone; camera; payment; autoplay; serial; clipboard-read; clipboard-write; display-capture;"
    />

    <div
      v-if="callError"
      class="call-failed__container d-flex flex-column justify-center align-center"
    >
      <div class="mb-1">{{ $t('workspaceVideo.callFailed') }}</div>
      <v-btn @click="$emit('back')">{{ $t('input.back') }}</v-btn>
    </div>
  </v-card>
</template>
<style lang="scss">
.vidyo__container {
  height: 100%;
  width: 100%;
  overflow: hidden;

  .call-failed__container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    font-size: 1.3em;
    div {
      color: inherit;
    }
  }
}
</style>
<script>
import { VIDYO_IFRAME_URL } from '../constants/env';
import GET_VIDYO_CALL from '../graphql/Query/PublicGetVidyoCall.gql';

export default {
  name: 'VidyoChatContainer',
  data() {
    return {
      callError: false,
      VIDYO_IFRAME_URL,
    };
  },
  props: {
    vidyo: Object,
  },
  created() {
    const handleReceiveMessage = (event) => {
      const action = event.data.type;

      switch (action) {
        case 'mount': {
          this.inLobby = true;
          this.sendMountedMsg();
          break;
        }
        case 'joined': {
          this.$emit('meetingJoined');

          break;
        }
        case 'disconnected': {
          this.$emit('leftMeeting');
          break;
        }
        case 'failed': {
          this.callError = true;
          break;
        }
        default: {
          console.warn('Iframe api method not supported.');
        }
      }
    };
    // Add an event listener to listen for messages from the iframe
    window.addEventListener('message', handleReceiveMessage);
  },
  methods: {
    endCall() {
      this.sendMessageToIframe('disconnect');
    },
    async getVidyoCall() {
      const { facilitationToken } = this.$store.state;
      try {
        const result = await this.$apollo.query({
          query: GET_VIDYO_CALL,
          variables: {
            facilitationToken,
          },
          fetchPolicy: 'no-cache',
        });
        return result.data.publicGetVidyoCall;
      } catch {
        return {};
      }
    },
    sendMessageToIframe(eventName, data) {
      const event = {
        type: eventName,
        data,
      };
      const iframe = document.getElementById('videoCall');
      iframe.contentWindow.postMessage(event, '*');
    },
    async sendMountedMsg() {
      const data = await this.getVidyoCall();
      const { firstName, lastName, roomPin, roomUrl } = data;
      this.sendMessageToIframe('mount response', {
        message: {
          firstName,
          lastName,
          vidyo: {
            roomPin,
            roomUrl,
          },
        },
      });
    },
  },
};
</script>
